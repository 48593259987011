import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserService} from '@app/old_services/user.service';
import {IDossierBien} from '@models/bien/bien.interfaces';

@Component({
    selector: 'app-dossier-bien-photos',
    templateUrl: 'dossier-bien-photos.component.html',
    styleUrls: ['dossier-bien-photos.component.scss']
})
export class DossierBienPhotosComponent {
    @Output() getPhotosIdx = new EventEmitter<number>();
    private _userService: UserService;
    private _dossier!: IDossierBien;
    private _photosIdx = 0;

    constructor(userService: UserService) {
        this._userService = userService;
    }

    get dossier(): IDossierBien {
        return this._dossier;
    }

    @Input()
    set dossier(value: IDossierBien) {
        this._dossier = value;
    }

    get photosIdx(): number {
        return this._photosIdx;
    }

    @Input()
    set photosIdx(value: number) {
        this._photosIdx = value;
    }

    get urlMarianne(): string {
        return this._userService.getCurrentUser()?.site.etude.urlMarianne || '';
    }

    getCurrentIndex(currentIndex: number): void {
        this.getPhotosIdx.emit(currentIndex);
    }
}
